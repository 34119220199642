
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiContainer-maxWidthLg {
  max-width: 1620px !important;
}

p{
  line-height: 30px;
  letter-spacing: 1px;
}

h1{
  font-size: 60px;
  color: #080F58;
  font-weight: 400;
}

.container .MuiContainer-maxWidthLg {
  max-width: 1160px !important;
  position: relative;
}
.MuiPaper-elevation1{
  box-shadow:none !important;
}

.button_green_fill {
  color: #fff !important;
  background-color: #00C9A8 !important;
}

.button_yellow_fill {
  color: #fff !important;
  background-color: #FEC553 !important;
}

.button_green_outline{
  color: #00C9A8 !important;
  background: none !important;
  border: #00C9A8 2px solid !important;
}

.button {
  margin: 15px !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
  padding: 15px 40px !important;
  font-weight: 900 !important;
}

.button_section{
  margin-left: -15px;
}

.responsive_img img{
  width: 100%;
}



.heaing_section h1{
  font-size: 34px;
  font-family:typeface-roboto-slab;

}

.heaing_section p{
  font-size: 20px;

}

.heaing_section.white h1{
 color: #fff; 

}

.white_text{
  color: #fff !important; 
 }
 
.center{
  text-align: center;
}

.border_line_grey{
  background:#e5e5e5;
  height: 1.5px;
  margin-top: 15px;
  margin-bottom: 15px;
}