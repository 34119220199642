@font-face {
  font-family: 'Playfair-Display-extrabold';
  src: url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.woff2') format('woff2'),
      url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.woff') format('woff'),
      url('assets/fonts/playfairdisplay/PlayfairDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/poppins/Poppins-Regular.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Regular.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/poppins/Poppins-Medium.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Medium.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url('assets/fonts/poppins/Poppins-Bold.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-Bold.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/poppins/Poppins-SemiBold.woff2') format('woff2'),
      url('assets/fonts/poppins/Poppins-SemiBold.woff') format('woff'),
      url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


h1 {font-family: 'Playfair-Display-extrabold';}

p{
  font-family: 'Poppins-Regular';
}